<template>

  <div
    class="container"
    style="margin-top: 48px; margin-bottom: 48px;">
    <div class="DetailsPage__Content">
      <div
        class="ContactVdBlock"
        style="margin-left: auto;margin-right: auto;">
        <div class="col-md-12">
          <h1 class="title_faq">Frequently asked questions</h1>
          <ul class="nav nav-tabs faq">
            <li class="active faq_li"><a
              data-toggle="tab"
              href="#rent"
              class="faq_link">Rent-a-car</a>
            </li>
            <li class="faq_li"><a
              data-toggle="tab"
              href="#buy"
              class="faq_link">Buy-a-car</a></li>
          </ul>

          <div class="tab-content faq_tabs">
            <div
              id="rent"
              class="tab-pane active">
              <h3 class="headinng_sec">Is the offering available to all employees?</h3>
              <p class="text_sec">
                Yes, the offering is available to all permanent employees, based on affordability and normal credit requirements.
              </p>
              <h3 class="headinng_sec">Will there be repossessed vehicles as part of this offering?</h3>
              <p class="text_sec">
                No, there will not be any repossessed vehicles available.
              </p>
              <h3 class="headinng_sec">Are there specified minimum & maximum time periods for a rental?</h3>
              <p class="text_sec">
                Yes, there is minimum rental of 1 year and a maximum rental of 4 years.
              </p>
              <h3 class="headinng_sec">Will normal customer lending criteria apply?</h3>
              <p class="text_sec">
                Yes, normal customer lending criteria will apply. The rental instalment will be fixed for the rental period.
              </p>
              <h3 class="headinng_sec">Will employees need to disclose their salary to VAF staff?</h3>
              <p class="text_sec">
                Yes, a dedicated VAF team will be available to deal with these requests. This dedicated team will sign additional confidentiality agreements to maintain employee confidentiality.
              </p>
              <h3 class="headinng_sec">How many vehicles is an employee allowed to rent?</h3>
              <p class="text_sec">
                Currently and subject to affordability, employees are allowed only 1 rental vehicle at any time. This excludes any vehicles the employee may have financed on instalment sale.
              </p>
              <h3 class="headinng_sec">Who do I report any vehicle faults or defects to?</h3>
              <p class="text_sec">
                All vehicles will be covered by the existing/remaining manufacturer’s warranty. Any defect / fault in the vehicle must be taken up directly with the manufacturer via an authorised dealer.
              </p>
              <h3 class="headinng_sec">Will the installed tracking unit be used to monitor driver behaviour?</h3>
              <p class="text_sec">
                Yes, as the vehicle is owned by the Bank it is important for the Bank to monitor the use of the vehicle. It is also a requirement from the insurer that a tracking device be installed in the vehicle.
              </p>
              <h3 class="headinng_sec">Is maintenance of the vehicle included in the monthly rental?</h3>
              <p class="text_sec">
                No, the rental only covers the vehicle usage, insurance, tracking, roadside assist and smash and grab. It does not cover the maintenance/servicing of the vehicle. It also does not cover fuel, toll fees, tyres, vehicle license renewals and traffic fines - these are for the cost of the employee.
              </p>
              <h3 class="headinng_sec">Who can drive the vehicle?</h3>
              <p class="text_sec">
                A driver with a valid driver’s license and as per insurance terms & conditions.
              </p>
              <h3 class="headinng_sec">May I fit extras and/or accessories to the rental vehicle?</h3>
              <p class="text_sec">
                No extras and/or accessories may be fitted to the rental vehicle.
              </p>
              <h3 class="headinng_sec">Who is liable to maintain / service the vehicle?</h3>
              <p class="text_sec">
                The employee is responsible for the regular maintenance or service of the vehicle in accordance with the manufacturer’s maintenance or service plan, where applicable.
              </p>
              <h3 class="headinng_sec">Will all vehicles have a maintenance or service plan?</h3>
              <p class="text_sec">
                Vehicles will have the remaining term of the maintenance or service plan. Where the maintenance plan or service plan on a vehicle has lapsed, the employee will still be required to maintain and service the vehicle at their cost.
              </p>
              <h3 class="headinng_sec">Will there be a limit to the mileage for the term of the rental agreement?</h3>
              <p class="text_sec">
                There will be bands of mileage employees can choose from and it will be the responsibility of the employee to manage kilometres utilised.
              </p>
              <h3 class="headinng_sec">What if I exceed my contracted kilometres?</h3>
              <p class="text_sec">
                There will be an excess cents per kilometre (CPK) rate in the rental agreement that will be charged for excess usage.
              </p>
              <h3 class="headinng_sec">Can I terminate the rental agreement before the expiry of the term?</h3>
              <p class="text_sec">
                Yes, the employee is able to terminate the agreement before the term expires. However they will be liable for early termination fees as set out in the terms and conditions of the rental agreement.
              </p>
              <h3 class="headinng_sec">How often will vehicles be available and offered to employees on a rental basis?</h3>
              <p class="text_sec">
                Vehicles will be available as and when vehicles are required to be disposed of from a Standard Bank Fleet Management perspective. Vehicles can be rented on a first come, first serve basis via electronic submission only and subject to affordability.
              </p>
              <h3 class="headinng_sec">Can I trade in my current vehicle?</h3>
              <p class="text_sec">
                You can trade-in your current vehicle through an independent 3rd party. The trade-in of the current vehicle will not be linked to the rental of the new vehicle and nor will Standard Bank be involved in or take any responsibility for the trade-in of your current vehicle.
              </p>
              <h3 class="headinng_sec">What happens if I am interested in a vehicle in another province?</h3>
              <p class="text_sec">
                There will be a capability to move vehicles between major metro’s (i.e. Johannesburg, Pretoria, Cape Town, Durban, Port Elizabeth, Polokwane, Nelspruit and Bloemfontein). This arrangement will be for the costs of the employee.
              </p>
              <h3 class="headinng_sec">Can I purchase the vehicle before the end of the contract?</h3>
              <p class="text_sec">
                Yes, a market related purchase price will be supplied and the employee can either finance the vehicle or purchase it cash.
              </p>
              <h3 class="headinng_sec">Can the employee choose to purchase the vehicle after the rental term has expired?</h3>
              <p class="text_sec">
                Yes, a market related purchase price will be supplied and the employee can either finance the vehicle or purchase it cash.
              </p>
              <h3 class="headinng_sec">How many car keys and alarm/immobilizers will I receive?</h3>
              <p class="text_sec">
                2 sets. Both sets must be returned to the bank at the end of the rental term.
              </p>
              <h3 class="headinng_sec">In the event of an accident, damage, theft, etc. of the rental vehicle, is there an excess payable by the employee?</h3>
              <p class="text_sec">
                Yes, an excess payable in line with insurance requirements and as seen in the insurance terms and conditions.
              </p>
              <h3 class="headinng_sec">If the rental vehicle is involved in an accident, damaged, stolen, written off, etc., do I receive a replacement rental vehicle?</h3>
              <p class="text_sec">
                Yes, a hired vehicle is supplied on a short-term basis as part of the insurance and subject to the terms of the insurance policy and the rental agreement.
              </p>
              <h3 class="headinng_sec">What happens to the interest rate if I exit the Bank?</h3>
              <p class="text_sec">
                The interest rate remains fixed for the entire rental period even if the employee leaves the employ of the bank.
              </p>
              <h3 class="headinng_sec">Who do I contact for any questions/queries relating to my rental vehicle?</h3>
              <p class="text_sec">
                Application and contract signing: vafstaff@standardbank.co.za
                <br>
                <br>
              </p>
              <p class="text_sec">
                Lifecycle of the rental agreement: EmployeeRentalScheme@standardbank.co.za
              </p>
            </div>
            <div
              id="buy"
              class="tab-pane ">
              <h3 class="headinng_sec">Is the offering available to all employees?</h3>
              <p class="text_sec">
                Yes, the offering is available to all permanent employees, based on affordability and normal credit requirements.
              </p>

              <h3 class="headinng_sec">Will there be repossessed vehicles as part of this offering?</h3>
              <p class="text_sec">
                No, there will not be any repossessed vehicles available.
              </p>

              <h3 class="headinng_sec">Can I finance the vehicle under instalment sale or lease?</h3>
              <p class="text_sec">
                Vehicle can only be financed under an instalment sale.
              </p>

              <h3 class="headinng_sec">What are the minimum & maximum loan periods for finance agreement?</h3>
              <p class="text_sec">
                Minimum period of 1 year and a maximum of 7 years, subject to terms and conditions.
              </p>

              <h3 class="headinng_sec">Will normal customer lending criteria apply?</h3>
              <p class="text_sec">
                Yes, normal customer lending criteria will apply.
              </p>

              <h3 class="headinng_sec">Will employees need to disclose their salary to VAF staff?</h3>
              <p class="text_sec">
                Yes, a dedicated VAF team will be available to deal with these requests. This dedicated team will sign additional confidentiality agreements to maintain employee confidentiality.
              </p>

              <h3 class="headinng_sec">Will I receive a fleet card with the vehicle?</h3>
              <p class="text_sec">
                No, employees must obtain their own personal fleet or petrol card, in need.
              </p>

              <h3 class="headinng_sec">Am I required to insure the vehicle?</h3>
              <p class="text_sec">
                Yes, in accordance with the terms of your finance agreement.
              </p>

              <h3 class="headinng_sec">How many vehicles is an employee allowed to finance?</h3>
              <p class="text_sec">
                An employee is allowed to finance 2 vehicles at employee rates at any time subject to affordability. Any additional vehicles financed would be at customer rates. The cap of 2 vehicles excludes a vehicle rented under the employee rental scheme. This means that the employee can rent 1 vehicle under the employee rental scheme and finance 2 vehicles under instalment sale on staff rates, subject to affordability.
              </p>

              <h3 class="headinng_sec">Who is responsible for the maintenance of the vehicle?</h3>
              <p class="text_sec">
                The employee is responsible to service and maintain the vehicle at their own cost as per the manufacturer’s specifications.
              </p>

              <h3 class="headinng_sec">Who is allowed to drive the vehicle?</h3>
              <p class="text_sec">
                A driver with a valid driver’s license and as per insurers terms and conditions.
              </p>

              <h3 class="headinng_sec">May I fit extras and/or accessories to the vehicle?</h3>
              <p class="text_sec">
                Yes and at your own costs.
              </p>

              <h3 class="headinng_sec">When buying the vehicle cash, when and how do I make payment?</h3>
              <p class="text_sec">
                An invoice will be supplied with banking details and payment to be made on or before the due date stated in the invoice.
              </p>

              <h3 class="headinng_sec">Will all vehicles have a maintenance or service plan?</h3>
              <p class="text_sec">
                Vehicles will have the remaining term of the maintenance or service plan. Where the maintenance plan or service plan on a vehicle has lapsed, the employee will still be required to maintain and service the vehicle at their own cost.
              </p>

              <h3 class="headinng_sec">Can I include Value Added Products (VAPs) to my finance agreement?</h3>
              <p class="text_sec">
                Yes, as per the website options.
              </p>

              <h3 class="headinng_sec">Can I terminate the agreement before the expiry of the term?</h3>
              <p class="text_sec">
                Yes, in accordance with the terms and conditions of the finance agreement.
              </p>

              <h3 class="headinng_sec">Can I finance these vehicles through another Bank?</h3>
              <p class="text_sec">
                No, this offering is only valid through Standard Bank Vehicle & Asset Finance as this is an exclusive employee offering.
              </p>

              <h3 class="headinng_sec">How often will vehicles be available and offered to employees on a cash or finance basis?</h3>
              <p class="text_sec">
                Vehicles will be available as and when vehicles are required to be disposed of from a Standard Bank Fleet Management perspective. Vehicles can be purchased on a first come, first serve basis via electronic submission only and subject to affordability.
              </p>

              <h3 class="headinng_sec">Can I trade in my current vehicle?</h3>
              <p class="text_sec">
                You can trade-in your current vehicle through an independent 3rd party. The trade-in of the current vehicle will not be linked to the purchase of the new vehicle and nor will Standard Bank be involved in or take any responsibility for the trade-in of your current vehicle.
              </p>

              <h3 class="headinng_sec">What happens if I am interested in a vehicle in another province?</h3>
              <p class="text_sec">
                There will be a capability to move vehicles between major metro’s (i.e. Johannesburg, Pretoria, Cape Town, Durban, Port Elizabeth, Polokwane, Nelspruit and Bloemfontein). This arrangement will be for the cost of the employee.
              </p>

              <h3 class="headinng_sec">What happens to the interest rate if I exit the bank?</h3>
              <p class="text_sec">
                Customer rates to apply from date of termination of employment.
              </p>

              <h3 class="headinng_sec">How many car keys and alarm/immobilizers will I receive?</h3>
              <p class="text_sec">
                2 sets.
              </p>

              <h3 class="headinng_sec">Can I view the vehicle before entering into a finance agreement?</h3>
              <p class="text_sec">
                Yes, the employee can view the vehicle online and is able to physically view the vehicle by making an appointment on the website.
              </p>

              <h3 class="headinng_sec">Can I test drive the vehicle?</h3>
              <p class="text_sec">
                Yes, by arrangement and as scheduled on the website.
              </p>

              <h3 class="headinng_sec">Who do I contact for any questions/queries relating to the vehicle?</h3>
              <p class="text_sec">
                *Application and contract signing: vafstaff@standardbank.co.za
                <br>
                * Cash Purchases: fleet.sbsa@standardbank.co.za
              </p>

              <h3 class="headinng_sec"><span id="LICENSE_REGISTRATION_ROADWORTHY">License, Registration & Roadworthy</span></h3>
              <p class="text_sec">
                Standard Bank VAF will charge all buyers a mandatory license, registration & roadworthy fee on every vehicle purchased. (On the road costs).
              </p>

              <h3 class="headinng_sec"><span id="RECONDITIONING_PACKAGE">Exterior Reconditioning Package</span></h3>
              <p class="text_sec">
                The vehicles sold on the site are not brand new, they may have scuffs, dents and scratches. The exterior reconditioning package option is therefore provided as a service where your vehicle can get restored into a used car showroom condition.
              </p>
              <p class="text_sec">
                The package is only applicable to exterior repairs. Interior repairs and the current fair wear & tear condition of the vehicle is not included in this package. To better understand what is included and excluded as Fair Wear and Tear, please see below FAQ -“What is deemed as fair wear & tear?”
              </p>
              <p class="text_sec">
                Exterior repairs include.
                <ul>
                  <li>   Minor dents.</li>
                  <li>   Paint and rim scratches.</li>
                  <li>   Corner bumper scuff repairs.</li>
                  <li>   Tar removal.</li>
                </ul>
              </p>
              <p class="text_sec">
                McCarty’s Auto Trimmers has been approved as a Standard Bank Service provider, they are one of the largest motor trimming companies and they offer a wide variety of interior and exterior repairs and custom services for any vehicle. Their skilled team will expertly repair your vehicle to top showroom condition.
              </p>
              <p class="text_sec">
                Your vehicle will be repaired before you take delivery and when that is not possible, the service provider will arrange a time and place that suits you to do the repairs.
              </p>

              <h3 class="headinng_sec">What is deemed as fair wear & tear?</h3>
              <p class="text_sec">
                Fair  wear  and  tear  is  defined  as  the  degree  of  deterioration  deemed  to  be  reasonable  when  a  vehicle  is  returned  on termination  of  a  contract.  The  following  standards  assume  that  the  vehicle  is  clean  and  complete,  structurally  sound,  all mechanical and electrical components are  in working order and that it is compliant  with the manufacturer’s specifications and with South African legislation.
              </p>
              <p>
                <img 
                  class="center" 
                  src="@/assets/img/table.png">
              </p>

              <h3 class="headinng_sec_3"><span id="SMASH_AND_GRAB">Smash and Grab</span></h3>
              <h3 class="headinng_sec">Lumar Smash & Grab</h3>
              <h3 class="headinng_sec">Background</h3>
              <p class="text_sec">
                <ul>
                  <li>OEM approvals – 14</li>
                  <li>Direct supply arrangements with 10 SA OEM’s / Importers
                    <ul>
                      <li>Toyota, Isuzu, Opel, Renault, Nissan, Ford, BMW, Volvo, Kia, Hyundai</li>
                    </ul>
                  </li>
                  <li>National network
                    <ul>
                      <li>Largest network of fitment centres in the country</li>
                    </ul>
                  </li>
                </ul>
              </p>

              <h3 class="headinng_sec">Benefits of LLumar Safety Film</h3>
              <p class="text_sec">
                <ul>
                  <li>Best in its class - world class quality film backed by Eastman and the PG Group</li>
                  <li>Enhances vehicle safety and security</li>
                  <li>Enhances comfort and wellbeing
                    <ul>
                      <li>Recommended by the Skin Cancer Foundation in USA</li>
                      <li>Endorsed by the British Skin Foundation</li>
                    </ul>
                  </li>
                  <li>Comprehensive Warranty
                    <ul>
                      <li>Additional warranty against colour fading</li>
                    </ul>
                  </li>
                  <li>LLumar embossed on film – if it doesn’t say LLumar – it’s not!</li>
                </ul>
              </p>
              <p>
                <img 
                  width="400px" 
                  src="@/assets/img/lumar.png" 
                  class="center" >
              </p>

              <h3 class="headinng_sec_3"><span id="TRACKING_DEVICE">Tracking Device</span></h3>
              <h3 class="headinng_sec">Vehicle Tracking</h3>
              <h3 class="headinng_sec">Background</h3>
              <p>
                We have partnered with Netstar to provide a range of affordable and feature rich vehicle tracking offerings.
              </p>

              <h3 class="headinng_sec">Products and Features</h3>
              <p>
                <ul>
                  <li>Netstar Nano + Nano Backup
                    <ul>
                      <li>The most affordable option for hi-jacking and stolen vehicle recovery
                        <ul>
                          <li>Stolen Vehicle Recovery Services</li>
                          <li>Fitment Certificate for Insurance</li>
                          <li>Wireless Unit with 3-Year Battery Life</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>Netstar Plus + Nano Backup
                    <ul>
                      <li>A feature-rich vehicle tracking and recovery option with added safety and convenience functionality
                        <ul>
                          <li>Stolen Vehicle Recovery Services</li>
                          <li>Fitment Certificate for Insurance</li>
                          <li>Signal Jamming Detection Alert (JammingResistTM)</li>
                          <li>Impact Detection for Safety</li>
                          <li>Battery Disconnect Alert</li>
                          <li>Web & App for Live Tracking</li>
                          <li>Logbook for SARS</li>
                          <li>Personal Driver Behaviour Rating</li>
                          <li>Car Park Jamming Alert</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>Netstar Early Warning + Nano Back Up
                    <ul>
                      <li>A comprehensive vehicle tracking and recovery option with all-round protection for you and your family
                        <ul>
                          <li>Stolen Vehicle Recovery Services</li>
                          <li>Fitment Certificate for Insurance</li>
                          <li>Signal Jamming Detection Alert (JammingResistTM)</li>
                          <li>Impact Detection for Safety</li>
                          <li>Battery Disconnect Alert</li>
                          <li>Web & App for Live Tracking</li>
                          <li>Logbook for SARS</li>
                          <li>Personal Driver Behaviour Rating</li>
                          <li>Car Park Jamming Alert</li>
                          <li>Auto-arm Proximity Tag for Security</li>
                          <li>Wireless Panic Button</li>
                          <li>Early Warning Theft Alert</li>
                          <li>Tow-away Alert</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>

              <h3 class="headinng_sec_3">Tracking Device</h3>
              <p>
                <img src="@/assets/img/aa.png" >
              </p>
              <p>
                <b><i>* AA Fleetcare has a total annual limit of R3 000 per vehicle</i></b><br>
                <b><i>* AA Fleetcare Plus has a total annual limit of R5 000 per vehicle</i></b>
              </p>

              <h3 class="headinng_sec_3"><span id="FINES_LICENSE_MANAGEMENT_QUOTE">Fines and Licensing</span></h3>
              <h3 class="headinng_sec">Fines and Licence Management</h3>
              <h3 class="headinng_sec">Background</h3>
              <p>
                We make it easy to manage payable traffic fines and renew vehicle licenses. You simply complete the online registration and register an account.
              </p>

              <h3 class="headinng_sec">Services</h3>
              <p>
                <ul>
                  <li>Instant traffic fine notifications</li>
                  <li>Reminders of upcoming license renewals</li>
                  <li>Electronic payment</li>
                  <li>Refund on discount negotiated</li>
                </ul>
              </p>

            </div>
          </div>
        </div>
      </div><!-- end VdBlock -->
    </div><!-- end DetailsPage__Content -->
  </div><!-- end Container -->
</template>

<script>
var ScrollTo = require("vue-scrollto");

export default {
  name:"FAQ",
  watch: {
    $route() {
      this.selectTab();
    }
  },
  mounted() {
    this.$nextTick(() => {
      $("ul.nav-tabs li a").click(function() {
        $("ul.nav-tabs li.active").removeClass("active");
        $(this)
          .parent("li")
          .addClass("active");
      });
    });

    this.selectTab();

    if(this.$route.params.section) {
      ScrollTo.scrollTo(this.$route.params.section)
    }
  },
  methods: {
    selectTab() {
      $("ul.nav-tabs li.active").removeClass("active");
      $(".tab-pane").removeClass("active");
      $(this.$route.hash).addClass("active")
      $("a[href='"+this.$route.hash+"']")
        .parent("li")
        .addClass("active");
    }
  }
}
</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ul > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

ul > li:before {
  content: "-";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ul > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

li ul > li:before {
  content: "-";
}

.Table
{
    display: table;
    width: 100%;
}
.Title
{
    display: table-caption;
    font-weight: bold;
    font-size: larger;
}
.Heading
{
    display: table-row;
    font-weight: bold;
}
.Row
{
    display: table-row;
}
.Cell
{
    display: table-cell;
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px;
}
.Cell1
{
    display: table-cell;
    width: 30%;
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px;
}
</style>
